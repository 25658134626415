<template>
  <div class="payments" v-if="hasPerm('payments.view_analyticaccount')">
    <page-header
      title="Répartition analytique des avoirs"
      icon="fa fa-magnifying-glass-chart"
      :links="getLinks()"
    ></page-header>
    <div class="sub-header">
      <b-row>
        <b-col cols="9">
          <date-range-selector
            prefix-label="Généré "
            :free-period="true"
            @change="onDateRangeChanged"
          ></date-range-selector>
        </b-col>
        <b-col>
          <b-form-group
            id="only-school-year-group"
            label="Saison"
            label-for="only-school-year"
          >
            <b-select v-model="onlySchoolYear" id="only-school-year" @change="loadElements()">
              <b-select-option v-for="schoolYear of schoolYears" :key="schoolYear.id" :value="schoolYear">
                {{ schoolYear.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
          <div class="text-right">
            <a href @click.prevent="showGroup = false" class="btn btn-xs btn-primary" v-if="showGroup">
              <i class="fa fa-plus"></i> de détails
            </a>
            <a href @click.prevent="showGroup = true" class="btn btn-xs btn-secondary" v-if="!showGroup">
              <i class="fa fa-minus"></i> de détails
            </a>
            &nbsp;
            <a href @click.prevent="loadElements()"><i class="fa fa-refresh"></i></a>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group label="Filtre analytique" label-for="filterAnalytic">
            <b-input-group prepend="prepend">
              <b-input
                id="filterAnalytic"
                type="text"
                placeholder="Filtrer les analytiques"
                v-model="analyticFilter"
              ></b-input>
              <template #prepend>
                <span class="input-group-text"><i class="fa fa-search"></i></span>
              </template>
              <template #append>
                <b-button variant="outline-secondary" @click.prevent="analyticFilter = ''">
                  <i class="fa fa-eraser"></i>
                </b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <date-frame-selector
            label="Utilisé Du"
            :disabled="!(startDate && endDate)"
            @change="onUsageDateRangeChanged"
          >
          </date-frame-selector>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Filtre famille"
            label-for="filterEntity"
            description="ne prendre en compte qu'une seule famille"
          >
            <b-input
              type="number"
              class="small-input"
              placeholder="N° de famille"
              v-model="entityNumber"
              id="filterEntity"
            ></b-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div ref="printMe">
      <div class="hide-here">
        <h2>Comptabilité analytique du {{ startDate | dateToString }} au {{ endDate | dateToString }}</h2>
      </div>
      <div v-if="!isLoading(loadingName) && elements.length === 0">
        <b>Aucun élement</b>
      </div>
      <div ref="excelTable">
        <table class="table table-bordered" v-if="!isLoading(loadingName) && elements.length">
          <tr>
            <th>Analytique</th>
            <th class="text-center origins">Avoirs</th>
            <th class="text-center usages1">En cours</th>
            <th class="text-center usages2">Utilisé</th>
            <th class="text-center usages2" v-if="hasUsage">Utilisé sur la période</th>
            <th class="text-center usages3">Remboursé</th>
            <th class="text-center usages3" v-if="hasUsage">Remboursé sur la période</th>
          </tr>
          <tr v-for="elt in elements" :key="elt.getKey()">
            <td class="line-header">
              <span :title="elt.analyticAccount.getLabel()">
                {{ elt.analyticAccount.name }} {{ elt.getYear() }}
                <i class="fa fa-list" title="Groupe" v-if="elt.analyticAccount.id < 0"></i>
              </span>
            </td>
            <td
              class="number origins" title="Avoirs générés" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'cancellation_credits')"
            >
              {{ elt.getCreditOrigin() | currency }}
            </td>
            <td
              class="number usages1" title="Avoirs en cours" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'remaining_credits')"
            >
              {{ elt.getAvailableCredits() | currency }}
            </td>
            <td
              class="number usages2" title="Avoirs utilisés" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'used_credits')"
            >
              {{ elt.getUsedCredits() | currency }}
            </td>
            <td
              class="number usages2" title="Avoirs utilisés sur la pếriode" v-b-tooltip
              v-if="hasUsage"
              @click.prevent="onShowDetail(elt, 'period_used_credits')"
            >
              {{ elt.getPeriodUsedCredits() | currency }}
            </td>
            <td
              class="number usages3" title="Avoirs remboursés" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'refund_credits')"
            >
              {{ elt.getRefund() | currency }}
            </td>
            <td
              class="number usages3" title="Avoirs remboursés sur la période" v-b-tooltip
              v-if="hasUsage"
              @click.prevent="onShowDetail(elt, 'period_refund_credits')"
            >
              {{ elt.getPeriodRefund() | currency }}
            </td>
          </tr>
          <tr>
            <th></th>
            <th class="text-center origins">Avoirs</th>
            <th class="text-center usages1">En cours</th>
            <th class="text-center usages2">Utilisé</th>
            <th class="text-center usages2" v-if="hasUsage">Utilisé sur la période</th>
            <th class="text-center usages3">Remboursé</th>
            <th class="text-center usages3" v-if="hasUsage">Remboursé sur la période</th>
          </tr>
          <tr>
            <th>Totaux</th>
            <th class="number origins">{{ sumCreditsOrigin | currency }}</th>
            <th class="number usages1">{{ sumAvailableCredits | currency }}</th>
            <th class="number usages2">{{ sumUsedCredits | currency }}</th>
            <th class="number usages2" v-if="hasUsage">{{ sumPeriodUsedCredits | currency }}</th>
            <th class="number usages3">{{ sumRefund | currency }}</th>
            <th class="number usages3" v-if="hasUsage">{{ sumPeriodRefund | currency }}</th>
          </tr>
        </table>
      </div>
      <analytic-accounting-detail-modal
        :start-date=startDate
        :end-date=endDate
        :usage-start-date=usageStartDate
        :usage-end-date=usageEndDate
        :field-name="selectedFieldName"
        :analytic-account="selectedAnalytic"
        :school-year="selectedSchoolYear"
        :entity-number="entityNumber"
        modal-id="bv-modal-analytic-accounting-detail"
        url="credits"
      ></analytic-accounting-detail-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'
import AnalyticAccountingDetailModal from '@/components/Accounting/AnalyticAccountingDetailModal'
import LoadingGif from '@/components/Controls/LoadingGif'
import DateRangeSelector from '@/components/DateRange/DateRangeSelector'
import PageHeader from '@/components/Layout/PageHeader'
import { makeAnalyticDetail } from '@/types/payments'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi, openDocument } from '@/utils/http'
import { sum } from '@/utils/math'

export default {
  name: 'AnalyticAccountingCredit',
  mixins: [BackendMixin],
  components: {
    DateFrameSelector,
    AnalyticAccountingDetailModal,
    DateRangeSelector,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'AnalyticAccountingCredit',
      allElements: [],
      dates: [],
      startDate: null,
      endDate: null,
      usageStartDate: null,
      usageEndDate: null,
      selectedFieldName: '',
      selectedAnalytic: null,
      selectedSchoolYear: null,
      schoolYears: [],
      onlySchoolYear: null,
      showGroup: false,
      analyticFilter: '',
      entityNumber: '',
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
  },
  computed: {
    hasUsage() {
      return this.usageStartDate || this.usageEndDate
    },
    elements() {
      let elements = []
      if (this.onlySchoolYear && this.onlySchoolYear.id) {
        elements = this.allElements.filter(
          elt => elt.schoolYear.id === this.onlySchoolYear.id
        )
      } else {
        elements = this.allElements
      }
      if (!this.showGroup) {
        if (this.analyticFilter) {
          const analyticValue = this.analyticFilter.toLowerCase()
          elements = elements.filter(
            itm => itm.analyticAccount.name.toLowerCase().indexOf(analyticValue) >= 0
          )
        }
        return elements
      } else {
        let groupedElements = []
        let groupMap = new Map()
        for (const element of elements) {
          if (element.analyticGroup.id) {
            const key = '' + element.analyticGroup.id + ':' + element.schoolYear.id
            if (groupMap.has(key)) {
              const index = groupMap.get(key)
              groupedElements[index].add(element)
            } else {
              const newIndex = groupedElements.length
              groupedElements.push(element.initGroup())
              groupMap.set(key, newIndex)
            }
          } else {
            groupedElements.push(element)
          }
        }
        if (this.analyticFilter) {
          const analyticValue = this.analyticFilter.toLowerCase()
          groupedElements = groupedElements.filter(
            itm => itm.analyticAccount.name.toLowerCase().indexOf(analyticValue) >= 0
          )
        }
        return groupedElements
      }
    },
    sumCredits() {
      return sum(this.elements.map(elt => elt.getCreditPayments()))
    },
    sumCreditsOrigin() {
      return sum(this.elements.map(elt => elt.getCreditOrigin()))
    },
    sumAvailableCredits() {
      return sum(this.elements.map(elt => elt.getAvailableCredits()))
    },
    sumUsedCredits() {
      return sum(this.elements.map(elt => elt.getUsedCredits()))
    },
    sumRefund() {
      return sum(this.elements.map(elt => elt.getRefund()))
    },
    sumPeriodUsedCredits() {
      return sum(this.elements.map(elt => elt.getPeriodUsedCredits()))
    },
    sumPeriodRefund() {
      return sum(this.elements.map(elt => elt.getPeriodRefund()))
    },
  },
  mounted() {
    this.loadSchoolYears()
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadSchoolYears() {
      let url = '/api/school-years/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.schoolYears = [makeSchoolYear({ name: 'Toutes', })].concat(
          resp.data.map(elt => makeSchoolYear(elt))
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadElements() {
      this.allElements = []
      if (this.startDate && this.endDate) {
        this.startLoading(this.loadingName)
        let url = '/api/analytic-accounting-credits/?start_date=' + this.startDate + '&end_date=' + this.endDate
        if (this.entityNumber) {
          url += '&entity=' + this.entityNumber
        }
        if (this.usageStartDate) {
          url += '&usage_start_date=' + this.usageStartDate
        }
        if (this.usageEndDate) {
          url += '&usage_end_date=' + this.usageEndDate
        }
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.allElements = resp.data.map(elt => makeAnalyticDetail(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    async onDateRangeChanged(event) {
      if ((this.startDate !== event.startDate) || (this.endDate !== event.endDate)) {
        this.startDate = event.startDate
        this.endDate = event.endDate
        await this.loadElements()
      }
    },
    onShowDetail(elt, fieldName) {
      if (elt.analyticAccount.id >= 0) {
        this.selectedAnalytic = elt.analyticAccount
        this.selectedSchoolYear = elt.schoolYear
        this.selectedFieldName = fieldName
        if (this.startDate && this.endDate) {
          this.$bvModal.show('bv-modal-analytic-accounting-detail')
        }
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async onUsageDateRangeChanged(event) {
      if ((this.usageStartDate !== event.startDate) || (this.usageEndDate !== event.endDate)) {
        this.usageStartDate = event.startDate
        this.usageEndDate = event.endDate
        await this.loadElements()
      }
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/?landscape=1'
      const docSlug = 'analytic-accounting' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'analytic-accounting' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped lang="less">
.header-line {
  padding: 5px;
  background: #e0e0e0;
  border-bottom: none;
}
.header-label {
  font-size: 1.2em;
  font-weight: bold;
}
tr td .show-on-hover {
  display: none;
}
tr:hover td .show-on-hover {
  display: block;
  font-size: 11px;
}
th.number, td.number {
  text-align: right !important;
  width: 10%;
}

th {
  background: #444;
  color: #fff;
}

tr:hover td {
  background: #f8f8f8;
}

tr:nth-of-type(even) td {
  background: #e5e4e4;
}
tr:hover td.line-header {
  background: #555555;
  color: #fff;
}
tr:hover td.delta {
  background: #555555;
  color: #fff;
}

th.origins {
  background: #583131 !important;
  color: #fff !important;
}

td.origins {
  background: #ffccc8;
  color: #000;
  cursor: pointer;
}

tr:nth-of-type(even) td.origins {
  background: #e8b4b0;
}
tr:hover td.origins {
  background: #583131;
  color: #fff;
}

th.usages1 {
  background: #25434a;
  color: #fff;
}

td.usages1 {
  background: #cfebef;
  color: #000;
  cursor: pointer;
}
tr:nth-of-type(even) td.usages1 {
  background: #bdd6da;
}
tr:hover td.usages1 {
  background: #25434a;
  color: #fff;
}

th.usages2 {
  background: #263c26;
  color: #fff;
}
td.usages2 {
  background: #c8ffc8;
  color: #000;
  cursor: pointer;
}
tr:nth-of-type(even) td.usages2{
  background: #c8efc8;
}
tr:hover td.usages2 {
  background: #263c26;
  color: #fff;
}

th.usages3 {
  background: #31332e;
  color: #fff;
}
td.usages3 {
  background: #fbffdb;
  color: #000;
  cursor: pointer;
}
tr:nth-of-type(even) td.usages3{
  background: #e9eecb;
}
tr:hover td.usages3 {
  background: #31332e;
  color: #fff;
}
th.origins, th.usages1, th.usages2, th.usages3,
td.origins, td.usages1, td.usages2, td.usages3 {
  width: 14%;
}

</style>
